import * as PIXI from "pixi.js";
import Vector from "./vector";

export default class Particle {
    constructor(x, y, mode = "yellow") {
        //velocityは単位ベクトルにする
        this.scale = this.setScale();
        this.mass = 1; //質量
        this.position = new Vector(x, y);
        this.velocity = this.setVelocity();
        this.acceleration = new Vector(0, 0);
        this.angle = Math.random() * 360;
        this.angle = 0;
        this.alpha = this.scale;
        this.g = new PIXI.Container();

        this.mode = mode;
        this.init();
    }

    getParam(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    init() {
        this.factory();
    }

    randLimit(min, max) {
        return Math.random() * (max - min) + min;
    }

    setScale() {
        return this.randLimit(0.4, 1);
    }

    setVelocity() {
        const v = -0.5 * this.scale * this.scale;
        return new Vector(0, v);
    }

    applyForce(force) {
        const f = force.divide(this.mass);
        this.acceleration = this.acceleration.add(f);
    }

    checkEdges() {
        if (this.position.y <= -this.g.height) {
            this.position.y = window.innerHeight;
            this.position.x = window.innerWidth * Math.random();
            this.scale = this.setScale();
            this.acceleration = new Vector(0, 0);
            this.velocity = this.setVelocity();
            this.angle = 0;
            this.alpha = this.scale;
        }
    }

    update(t, scrollTopDiff) {
        let _scrollTopDiff = scrollTopDiff >= 0 ? scrollTopDiff : 0; //逆方向はカット
        let power = window.innerWidth < 1024 ? 2 : 3;
        const scrollDiffVec = new Vector(0, (-1 * _scrollTopDiff) / power);

        this.velocity = this.velocity.add(this.acceleration);
        this.position = this.position.add(this.velocity);
        this.position = this.position.add(scrollDiffVec); //スクロールDIFFの動きを追加
        this.angle += 0.5;

        if (this.position.y <= window.innerHeight / 4) {
            this.alpha += this.velocity.y * 0.01;
        }

        this.checkEdges();
        this.display();
    }

    factory() {
        const graphics = new PIXI.Graphics();
        const unit = window.innerWidth < 1024 ? 10 : 15;
        const path = [unit / -2, unit * 0.9, unit / 2, unit * 0.9, 0, 0];
        let color = 0xe6b422;

        if (this.getParam("black")) {
            color = 0xdc0000;
            document.body.classList.add("-blackmode");

            if (document.body.getAttribute("data-canvasmode") === "black") {
                color = 0x000000;
            }
        }

        graphics.beginFill(color);
        graphics.drawPolygon(path);
        graphics.endFill();

        this.g.addChild(graphics);

        this.g.pivot.x = this.g.width / 2;
        this.g.pivot.y = this.g.height / 2;
    }

    getUnitV() {
        let distvector = this.distination.subtract(this.position).unit();
        return distvector;
    }

    display() {
        this.g.position.x = this.position.x;
        this.g.position.y = this.position.y;
        this.g.alpha = this.alpha;
        this.g.rotation = (this.angle * Math.PI) / 180;
        this.g.scale.x = this.scale;
        this.g.scale.y = this.scale;
    }

    remap() {
        //再配置する
        this.position.x = window.innerWidth * Math.random();
    }
}
