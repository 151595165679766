import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default class ScrollFunc {
    constructor() {
        this.init();
    }

    init() {
        this.onScroll();
        this.smoothScroll();
    }

    onScroll() {
        const revealElm = [...document.querySelectorAll(".js-scrollreveal")];

        revealElm.forEach((el) => {
            ScrollTrigger.create({
                trigger: el,
                start: "top 85%",
                //markers: true,
                onEnter: () => {
                    el.classList.add("-reveal");
                },
            });
        });
    }

    smoothScroll() {
        const triggers = [...document.querySelectorAll(".js-smoothscroll")];
        const pagetop = [...document.querySelectorAll(".js-pagetop")];

        triggers.forEach((el) => {
            el.addEventListener("click", (e) => {
                e.preventDefault();
                const hash = el.getAttribute("href");
                const offset = window.innerWidth <= 768 ? window.innerWidth * 0.3 : 200 * (window.innerWidth / 1920);
                gsap.to(window, { duration: 1, scrollTo: { y: hash, offsetY: offset }, ease: "circ.inOut" });
            });
        });

        pagetop.forEach((el) => {
            el.addEventListener("click", (e) => {
                e.preventDefault();
                gsap.to(window, { duration: 1, scrollTo: { y: 0 }, ease: "circ.inOut" });
            });
        });
    }
}
