//common funcs
import { screenHeight } from "./common/function/screenheight.es6";
import { gnav } from "./common/function/gnav.es6";

//common classes
import Modal from "./common/classes/modal.es6";
import ScrollFunc from "./common/classes/scrollfunc.es6";
import Movie from "./common/classes/movie.es6";
import Title from "./common/classes/title.es6";
import Canvas from "./common/classes/canvas/canvas.es6";

class Common {
    constructor() {
        this.canvas = new Canvas();
        this.modal = new Modal();
        this.scrollFunc = new ScrollFunc();
        this.movie = new Movie();
        this.title = new Title();
        this.init();
    }

    init() {
        //common funcs
        screenHeight();
        gnav();
    }

    resetModalEvents() {
        this.modal.resetEvent();
    }
}

const common = new Common();
