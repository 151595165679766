import * as PIXI from "pixi.js";
import Particle from "./particle";

export default class Canvas {
    constructor() {
        this.app = null;
        this.particles = [];
        this.resizeTimer = null;
        this.init();
    }

    init() {
        this.setupPixi();
        this.eventBind();
    }

    lerp(a, b, n) {
        return (1 - n) * a + n * b;
    }

    setupPixi() {
        this.app = new PIXI.Application({
            width: window.innerWidth,
            height: window.innerHeight,
            view: document.querySelector(".js-canvas"),
            resolution: window.devicePixelRatio || 1,
            backgroundAlpha: 0,
            autoResize: true,
        });

        this.setupCanvas();
    }

    setupCanvas() {
        const fps = 60;
        let now = performance.now();
        let start = 0;
        let scrollTop = 0;
        let scrollTopPrev = 0;
        let scrollTopDiff = 0;

        const particles = this.particles;
        const widthRatio = window.innerWidth / 1920;
        let amount = window.innerWidth < 1024 ? 18 : 40; //1920で75個基準
        const mode = document.body.getAttribute("data-canvasmode");

        if (!window.innerWidth >= 1024) {
            amount *= widthRatio;
        }

        for (let i = 0; i < amount; i++) {
            const particle = new Particle(Math.random() * window.innerWidth, Math.random() * window.innerHeight, mode);
            particles.push(particle);
            this.app.stage.addChild(particle.g);
        }

        let animate = (timestamp) => {
            let delta = Math.floor(timestamp - now);
            scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            scrollTopDiff = this.lerp(scrollTopDiff, scrollTop - scrollTopPrev, 0.1);

            if (start === 0) {
                start = timestamp;
            }

            let elapsed = (timestamp - start) / 1000;

            //フレームスキップ実装はスマホではなしに（FPSがかなり出るため…）
            if (window.innerWidth >= 1024) {
                //deltaが16.66ms(60FPS)以上あるときは、1フレームあたりの値がFPSが低くなっているので、
                //本来の分だけupdateしてフレームスキップを実装する
                while (delta >= 0) {
                    particles.forEach((particle) => particle.update(elapsed, scrollTopDiff));
                    delta -= 1000.0 / fps;
                }
            } else {
                particles.forEach((particle) => particle.update(elapsed, scrollTopDiff));
            }

            //フレームスキップした結果を描画
            particles.forEach((particle) => particle.display());
            now = timestamp;
            scrollTopPrev = scrollTop;

            requestAnimationFrame(animate);
        };

        animate(performance.now());
    }

    eventBind() {
        window.addEventListener("resize", this.setFullscreenCanvas.bind(this));
    }

    setFullscreenCanvas() {
        //リサイズイベントがスクロールで連続で発生しないようにする
        clearTimeout(this.resizeTimer);
        const particles = this.particles;

        this.resizeTimer = setTimeout(() => {
            //ratio,canvasサイズ、描画領域を再計算
            this.app.renderer.resize(window.innerWidth, window.innerHeight);
            particles.forEach((particle) => particle.remap());
        }, 100);
    }
}
