export default class Title {
    constructor() {
        this.init();
    }

    init() {
        this.randomTxtMove();
    }

    shuffleArray([...array]) {
        for (let i = array.length - 1; i >= 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    randomTxtMove() {
        const parent = [...document.querySelectorAll(".js-ttlwrap")];

        const setRandClass = () => {
            parent.forEach((el) => {
                const txts = [...el.querySelectorAll("span")];

                const lengthArray = [...Array(txts.length)].map((el, i) => i);
                const shuffledArray = this.shuffleArray(lengthArray);
                const picup = [shuffledArray[0]];

                txts.forEach((txt, index) => {
                    txt.classList.remove("-tr1");
                    txt.classList.remove("-tr2");

                    const randN = Math.floor(Math.random() * 2) + 1;

                    if (picup.includes(index)) {
                        setTimeout(() => {
                            txt.classList.add(`-tr${randN}`);
                        }, 10);
                    }
                });
            });

            setTimeout(setRandClass, 6000);
        };

        setRandClass();
    }
}
